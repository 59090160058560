export const prefectureOptions = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

export const spotOptions = [
  "正面ドア",
  "裏口ドア",
  "メール室",
  "エレベーター",
  "ゲート",
  "カスタム",
];

export const gateNumberOptions = ["1", "2"];

export const elevatorSpotOptions = ["エレベーター", "カスタム"];

// prettier-ignore
export const gateSpotOptions = [
  "正面ドア", "裏口ドア", "ゲート", "集中集合玄関機", "グランドエントランス", "カスタム"];

export const STORE_USERNAME_KEY = "pabbit_configuration_username";
// ❶❷❸❹❺❻❼❽❾❿

export const XIEZHEN_DEFAULT_URI =
  "https://pacport-static-dev.s3.ap-northeast-1.amazonaws.com/pabbit-dev/ac895b36-4f57-43f7-afa6-748d024cbdc9"; // elevator以外的设备的默认写真图片
export const XIEZHEN_DEFAULT_URI_ELEVATOR =
  "https://pacport-static-dev.s3.ap-northeast-1.amazonaws.com/pabbit-dev/1f0e9f12-ecf5-4c34-ad8e-ff53a5cdd086";
