import { useNavigate } from "react-router-dom";

import RectangleIcon from "@/assets/rectangle.svg?react";
import {
  Button,
  Card,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import {
  ConfigPanelContainer,
  ConfigPanelItem,
} from "@/components/config-panel";
import { useModal } from "@/hooks";
import { getStorage } from "@/shared/store";
// import { useRegisterDeviceToAmazon } from "@/queries";
// import { CloudUnlockERRNETWORKError } from "@/queries/requests/server";
// import { errorToast } from "@/shared";
import type { DeviceMode } from "@/types";

import { BasicInfoCard, BasicInfoItem, IMEIStatus } from "../components";
import { useGetLiteStatus } from "../hooks";

export default function () {
  const navigate = useNavigate();

  const { visible: objectSettingVisible, toggle: objectSettingToggle } =
    useModal();
  const { visible: lineNotOpenedVisible, toggle: lineNotOpenedToggle } =
    useModal();
  const { visible: spotSettingVisible, toggle: spotSettingToggle } = useModal();
  const { visible: unlockTestFirstVisible, toggle: unlockTestFirstToggle } =
    useModal();

  const { deviceStatus } = useGetLiteStatus();
  const isDeviceOperational = deviceStatus?.mode === "operational";
  // const { mutateAsync: registerToAmazon, isLoading: isRegistering } =
  //   useRegisterDeviceToAmazon();
  const sn = getStorage("serialNumber");
  const imei = getStorage("imei");
  const deviceId = getStorage("deviceId");

  const handleLineOpenedClick = () => {
    if (!deviceStatus?.bindedProperty) {
      objectSettingToggle();
      return;
    }
    navigate("/line-open");
  };
  const checkBindAndOpenStatus = (callback: () => void) =>
    function () {
      if (!deviceStatus?.bindedProperty) {
        objectSettingToggle();
        return;
      }
      if (deviceStatus.lineActived !== "active") {
        lineNotOpenedToggle();
        return;
      }
      callback();
    };
  const handleSpotSetting = checkBindAndOpenStatus(() => {
    if (!deviceStatus?.positionedSpotName) {
      navigate("/spot");
    } else {
      navigate(`/spot/${deviceId}`);
    }
  });

  const checkPositioned = (callback: () => void) =>
    function () {
      if (deviceStatus?.positionedSpotName !== 2) {
        spotSettingToggle();
        return;
      }
      callback();
    };

  const handleUnlockTestClick = checkBindAndOpenStatus(
    checkPositioned(() => {
      navigate("/unlock-overview");
    })
  );

  const checkUnlockTestPassed = (callback: () => void) =>
    function () {
      if (!deviceStatus?.isUnlockTestAllPassed) {
        unlockTestFirstToggle();
        return;
      }
      callback();
    };

  const handleModeToggleClick = checkBindAndOpenStatus(
    checkPositioned(
      checkUnlockTestPassed(() => {
        navigate("/mode-toggle");
      })
    )
  );

  // const handleNextClick = () => {
  //   registerToAmazon()
  //     .then(() => {
  //       !allDoneVisible && allDoneToggle();
  //     })
  //     .catch((error) => {
  //       if (error instanceof CloudUnlockERRNETWORKError) {
  //         errorToast("ネットワークタイムアウト");
  //       } else {
  //         !allDoneVisible && allDoneToggle();
  //       }
  //     });
  // };

  return (
    <>
      <Header>登録した情報</Header>
      <Main className="flex flex-col gap-3">
        <BasicInfoCard>
          <BasicInfoItem title="シリアル番号" content={sn} />
          <BasicInfoItem title="IMEI" content={imei} />
        </BasicInfoCard>
        <p className="mt-2 font-semibold">
          <RectangleIcon className="mb-[2px] inline h-4 w-4" />
          <span>設定手順</span>
        </p>
        <Card className="px-4 py-2">
          <ConfigPanelContainer>
            <ConfigPanelItem
              step={1}
              disabled={isDeviceOperational}
              iconLighted={!!deviceStatus?.bindedProperty}
              optionName="物件設定"
              placeholder="未設定"
              content={deviceStatus?.bindedProperty}
              onClick={() => navigate("/configure")}
            />
            <ConfigPanelItem
              step={2}
              disabled={isDeviceOperational}
              iconLighted={deviceStatus?.lineActived === "active"}
              optionName="回線開通"
              content={
                <IMEIStatus status={deviceStatus?.lineActived ?? "inactive"} />
              }
              onClick={handleLineOpenedClick}
            />
            <ConfigPanelItem
              step={3}
              disabled={isDeviceOperational}
              iconLighted={deviceStatus?.positionedSpotName === 2}
              optionName="設置場所と写真"
              placeholder="未設定"
              content={deviceStatus?.positionedSpotName === 2 && "設定済み"}
              onClick={handleSpotSetting}
            />
            <ConfigPanelItem
              step={4}
              disabled={isDeviceOperational}
              iconLighted={deviceStatus?.isUnlockTestAllPassed}
              optionName="解錠テスト"
              content={
                <>
                  <p>リレー解錠テスト</p>
                  <p>番号入力解錠テスト</p>
                  <p>クラウド解錠テスト</p>
                </>
              }
              onClick={handleUnlockTestClick}
            />
            <ConfigPanelItem
              step={5}
              disabled={isDeviceOperational}
              iconLighted={deviceStatus?.mode === "operational"}
              optionName="モード切替"
              content={<LiteModeCaption mode={deviceStatus?.mode} />}
              onClick={handleModeToggleClick}
            />
          </ConfigPanelContainer>
        </Card>
        <div className="mt-5">
          <Button
            disabled={!deviceStatus?.isSettingAllDone}
            onClick={() => navigate("/")}
            // loading={isRegistering}
          >
            設定を完了
          </Button>
        </div>
      </Main>
      <Footer />
      <Popup visible={objectSettingVisible}>
        <PopupMain>{"物件の設定を先に完了してください。"}</PopupMain>
        <PopupAction>
          <Button onClick={objectSettingToggle}>閉じる</Button>
        </PopupAction>
      </Popup>
      <Popup visible={lineNotOpenedVisible}>
        <PopupMain>
          {
            "回線が開通していないため、後続の操作ができません。先に回線を開通してください。"
          }
        </PopupMain>
        <PopupAction>
          <Button onClick={lineNotOpenedToggle}>閉じる</Button>
        </PopupAction>
      </Popup>
      <Popup visible={spotSettingVisible}>
        <PopupMain>
          <p className="mb-2 text-lg font-semibold">注意：</p>
          「設置場所」と「写真」の情報がありません。クラウド解錠テストを完了することができません。
        </PopupMain>
        <PopupAction>
          <Button onClick={spotSettingToggle}>閉じる</Button>
        </PopupAction>
      </Popup>
      <Popup visible={unlockTestFirstVisible}>
        <PopupMain>解錠テストを完了してください。</PopupMain>
        <PopupAction>
          <Button onClick={unlockTestFirstToggle}>閉じる</Button>
        </PopupAction>
      </Popup>
    </>
  );
}

const LiteModeCaption = ({ mode }: { mode?: DeviceMode }) => {
  switch (mode) {
    case "operational":
      return <span className="text-[#52C41A]">運用モード</span>;
    default:
      return <span className="text-[#FD8140]">デバッグモード</span>;
  }
};
