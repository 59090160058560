import { cx } from "@emotion/css";

export const Label = ({
  htmlFor,
  children,
  required,
  className,
}: {
  htmlFor: string;
  children: string;
  required?: boolean | string;
  className?: string;
}) => {
  return (
    <label htmlFor={htmlFor} className={cx("block", className)}>
      <p className={cx("mb-2 text-base font-normal")}>
        {children}&nbsp;
        {required && (
          <span className="text-danger">
            {typeof required === "string" ? required : "*"}
          </span>
        )}
      </p>
    </label>
  );
};
