import { useNavigate } from "react-router-dom";

import { Button, Footer, Header, Main } from "@/components";
import { Step, StepContainer, StepsGenerator } from "@/components/step";

import { liteStepsData } from "./lite-steps-data";

export function LiteRestart() {
  const navigate = useNavigate();
  return (
    <>
      <Header>デバイスをリセット</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={liteStepsData} />
          <Step>
            <div>
              <Button onClick={() => navigate("/status")}>
                リセットが完了
              </Button>
              <div className="text-[#909899] mt-2">
                <span className="text-[#EE421D]">※</span>
                再起動後、デバイスはバージョンアップ情報を検知し、自動的にアップグレードを開始する可能性があります。アップグレード完了した後、解錠テストを続けてください。
              </div>
            </div>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
}
