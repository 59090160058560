import { cx } from "@emotion/css";
import React from "react";

import ArrowIcon from "@/assets/arrow-right.svg?react";

interface ConfigPanelContainerProps {
  className?: string;
  children: React.ReactNode;
}

export const ConfigPanelContainer: React.FC<ConfigPanelContainerProps> = ({
  className,
  children,
}) => {
  return (
    <section
      className={cx(
        "divide-x-0 divide-y divide-solid divide-[#E9EDEE]",
        className
      )}
    >
      {children}
    </section>
  );
};

interface ConfigPanelItemProps {
  step?: number;
  iconLighted?: boolean;
  className?: string;
  optionName: string;
  content?: string | React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  onClick?: () => void;
}
export const ConfigPanelItem: React.FC<ConfigPanelItemProps> = ({
  step,
  iconLighted = false,
  className,
  optionName,
  content,
  placeholder,
  disabled,
  onClick,
}) => {
  return (
    <div
      className={cx("px-0 py-2", "text-left disabled:text-black", className)}
      onClick={() => {
        disabled || onClick?.();
      }}
    >
      <header className="flex items-center justify-between">
        <div>
          {step && (
            <span
              className={cx(
                "mr-2 inline-block h-4 w-4 rounded-full",
                "bg-[#C4CBCC] text-center text-xs font-medium text-white",
                iconLighted && "!bg-primary"
              )}
            >
              {step}
            </span>
          )}
          <span className="text-sm leading-6">{optionName}</span>
        </div>
        {disabled || (
          <ArrowIcon
            className={cx(
              "h-4 w-4 text-[#C4CBCC]",
              iconLighted && "!text-primary"
            )}
          />
        )}
      </header>
      <main
        className={cx(
          "min-h-4 text-xs",
          content ? "text-[#999999]" : "text-error",
          !!step && "ml-8"
        )}
      >
        {content || placeholder}
      </main>
    </div>
  );
};
