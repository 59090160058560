import type { FC, InputHTMLAttributes, ReactNode } from "react";
import type { UseFormRegisterReturn } from "react-hook-form";
import { cx } from "@emotion/css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegisterReturn<any>;
  helper?: ReactNode;
  styleType?: "shadow" | "outline";
}
export const Input: FC<InputProps> = ({
  register,
  className,
  helper,
  styleType = "outline",
  ...props
}) => {
  return (
    <div className="flex flex-col">
      <input
        className={cx([
          "form-input text-[15px]  placeholder:text-text-gray rounded-lg p-4 outline-0",
          "focus:border-transparent focus:ring-primary",
          {
            "bg-[#F2F4F5]  outline-gray-500 border-none":
              styleType === "shadow",
            "border-[#E3E5E5] border-solid": styleType === "outline",
          },
          className,
        ])}
        style={{ boxShadow: "0 0 #0000" }}
        {...props}
        {...register}
      />

      {helper && (
        <div className="mt-2 text-text-gray text-[15px]">{helper}</div>
      )}
    </div>
  );
};
