import { useNavigate } from "react-router-dom";

import ReturnIcon from "@/assets/return.svg?react";
import { Button, Footer, Header, Main } from "@/components";
import { Step, StepContainer, StepsGenerator } from "@/components/step";
import { useFetchDataError } from "@/hooks";
import { useGetTestResultsInterval } from "@/queries";
import type { ReportTestOption } from "@/types";

import { relayStepsData } from "./relay-steps-data";

export const RelayUnlock = () => {
  const relayUnlockReportTestKey: ReportTestOption = "1";
  const navigate = useNavigate();
  const { data, isError, error } = useGetTestResultsInterval(
    relayUnlockReportTestKey
  );
  useFetchDataError(isError, error);
  const handleRelayUnlock = () => {
    navigate(-1);
  };
  return (
    <>
      <Header>リレー解錠テスト</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={relayStepsData} />
          <Step>
            <div>
              <Button
                onClick={handleRelayUnlock}
                disabled={!data?.data?.[relayUnlockReportTestKey]}
              >
                リレー解錠を完了
              </Button>
              <p className="mt-2 text-[#909899]">
                <span className="text-error">※</span>
                解錠ができていない場合は、この完了ボタンを押さないでください。
              </p>
            </div>
          </Step>
          <Step>
            <div
              className="block text-center text-primary underline"
              onClick={() => navigate(-1)}
            >
              <ReturnIcon className="mb-1 mr-1 inline" />
              戻る
            </div>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
};
