import { useMutation } from "@tanstack/react-query";

import { XIEZHEN_DEFAULT_URI } from "@/app.constant";
import { getLiteBindInfo, NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getDeviceByID } from "@/shared/requests/device";
import {
  attachDeviceToProperty,
  getPropertyById,
  updateDevicePosition,
} from "@/shared/requests/property";
import type {
  AttachDeviceData,
  UpdateDevicePositionData,
} from "@/shared/requests/property.type";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";

export function useUpdateLitePosition() {
  const mutateFn = async () => {
    const device_id = getStorage("deviceId");
    if (!device_id)
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    const device = await getDeviceByID({
      device_id,
      device_type: "PabbitLite" as DeviceType,
    });
    if (device.data.position) {
      const property_id = getStorage("propertyId");
      const device_id = getStorage("deviceId");
      const spot = getStorage("spotName");
      const image_url = getStorage("uri") || XIEZHEN_DEFAULT_URI;
      if (!property_id || !device_id || !spot || !image_url) {
        // console.log(property_id, device_id, spot, image_url);

        throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
      }
      const positionData: UpdateDevicePositionData = {
        property_id,
        device_id,
        position: {
          spot,
          image_url,
        },
      };
      return updateDevicePosition(positionData);
    } else {
      const sn = getStorage("serialNumber");
      const device_type = getStorage("deviceType");
      const spot = getStorage("spotName");
      const image_url = getStorage("uri") || XIEZHEN_DEFAULT_URI;
      const property_id = getStorage("propertyId");
      let build_id = getLiteBindInfo(device_id)?.build_id;
      if (!build_id) {
        const property = await getPropertyById(property_id);
        build_id = property.data.buildings?.[0]?.build_id;
      }
      if (
        !sn ||
        !device_type ||
        !spot ||
        !image_url ||
        !property_id ||
        build_id === undefined
      ) {
        // console.log(sn, device_type, spot, property_id, build_id);
        throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
      }
      const positionData: AttachDeviceData = {
        sn,
        device_type,
        property_id,
        positions: [
          {
            spot,
            image_url,
            build_id,
          },
        ],
        setup_by: "manual",
      };
      return attachDeviceToProperty(positionData);
    }
  };
  return useMutation(mutateFn);
}
