import { cx } from "@emotion/css";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import ReturnIcon from "@/assets/return.svg?react";
import { Button, Footer, Header, Main } from "@/components";
import {
  Step,
  StepContainer,
  StepGraph,
  StepIndicator,
  StepParagraph,
} from "@/components/step";
import { useFetchDataError } from "@/hooks";
import { useGetTestResultsInterval } from "@/queries";
import type { ProviderModel, ReportTestOption } from "@/types";

import { useGenerateIssueCode } from "../hooks";
import {
  InputTestIMG,
  RelayUnlockClikedIMG,
  RoomUnlockDeliveriesIMG,
  ScannerTestIMG,
  UnlockFailedIMG,
  UnlockSucceedIMG,
} from "./assets";
import { LiteUnlockBarcode, LiteUnlockDeliveries } from "./components";

const RoomNumberUnlock = () => {
  const roomUnlockReportTestKey: ReportTestOption = "2";
  const navigate = useNavigate();
  const [provider, setProvider] = useState<ProviderModel>();
  const handleProviderSuccess = useCallback(
    (provider: ProviderModel | undefined) => {
      setProvider(provider);
    },
    []
  );
  const { data, isError, error } = useGetTestResultsInterval(
    roomUnlockReportTestKey
  );
  useFetchDataError(isError, error);

  const { code } = useGenerateIssueCode(provider?.courier);

  return (
    <>
      <Header>番号入力解錠テスト</Header>
      <Main>
        <StepContainer>
          <Step>
            <StepGraph>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <img src={RelayUnlockClikedIMG} className="w-full" />
                  <p className="my-2 text-center">図１</p>
                </div>
                <div>
                  <img src={RoomUnlockDeliveriesIMG} className="w-full" />
                  <p className="my-2 text-center">図2</p>
                </div>
              </div>
            </StepGraph>
          </Step>
          <Step>
            <StepParagraph>
              <StepIndicator>1</StepIndicator>
              図１ページの「次へ」を押して、番号入力解錠テストページに遷移します。
            </StepParagraph>
            <StepParagraph>
              デバイスで、表示されている宅配業者リストと以下の内容が一致していることを確認してください。
            </StepParagraph>
          </Step>
          {/* Deliveries */}
          <Step>
            <LiteUnlockDeliveries onProvidersSuccess={handleProviderSuccess} />
          </Step>
          <Step>
            <StepParagraph>
              <StepIndicator>2</StepIndicator>
              デバイスで
              <span className="text-primary">「{provider?.name ?? "-"}」</span>
              を選択してください
            </StepParagraph>
          </Step>
          <Step>
            <StepParagraph>
              <StepIndicator>3</StepIndicator>
              <span className="text-primary">「101」</span>
              部屋番号を入力して、「次へ」を押してください
            </StepParagraph>
          </Step>
          <Step>
            <StepGraph image={ScannerTestIMG} />
          </Step>
          <Step>
            <StepParagraph>
              <StepIndicator>4</StepIndicator>
              このページに「スキャン」をタップして、以下のバーコードをスキャンし、スキャンした数字がバーコードの数字と一致していることを確認してください。
            </StepParagraph>
          </Step>
          <Step>
            <LiteUnlockBarcode code={code} />
            <StepGraph image={InputTestIMG} />
            <StepParagraph>
              <StepIndicator>5</StepIndicator>
              数字が一致した場合、「確認」を押して、このページに進んでください。
              <br />
              下の数字を入力し、「照合」をタップし、 検証結果を確認ください。
            </StepParagraph>
            <StepParagraph>
              <div
                className={cx(
                  "my-1 h-12 w-full rounded-md bg-[#F4F6F7]",
                  "text-center text-xl font-medium leading-12 text-primary"
                )}
              >
                「{code}」
              </div>
            </StepParagraph>
            <StepParagraph>
              <StepIndicator>6</StepIndicator>
              入力が正しい場合、検証に成功し、以下の「照合に成功しました」ページが表示されます。「次へ」をタップして、モード切替のページに進みます。
            </StepParagraph>
            <StepParagraph>
              検証に失敗した場合は、「照合に失敗しました」ページが表示されます。「もう一度」をタップして、数字を再入力してください。
            </StepParagraph>
          </Step>
          <Step>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <img src={UnlockSucceedIMG} className="w-full" />
              </div>
              <div>
                <img src={UnlockFailedIMG} className="w-full" />
              </div>
            </div>
          </Step>
          <Step>
            <div>
              <Button
                onClick={() => navigate(-1)}
                disabled={!data?.data?.[roomUnlockReportTestKey]}
              >
                番号入力解錠を完了
              </Button>
              <p className="mt-2 text-[#909899]">
                <span className="text-error">※</span>
                番号入力解錠テストを完了した上で、クラウド解錠テストを完了してください。
              </p>
            </div>
          </Step>
          <Step>
            <div
              className="block text-center text-primary underline"
              onClick={() => navigate(-1)}
            >
              <ReturnIcon className="mb-1 mr-1 inline" />
              戻る
            </div>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
};

export const RoomNumberUnlockWrapper = () => {
  return <RoomNumberUnlock />;
};
