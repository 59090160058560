import { cx } from "@emotion/css";
import type { FC, ReactElement } from "react";

import BuildingTwoIcon from "@/assets/building-2.svg?react";
import HeadCountIcon from "@/assets/headcount.svg?react";
import HouseIcon from "@/assets/house.svg?react";
import PlacePinIcon from "@/assets/place-pin.svg?react";
import { Card } from "@/components";
import type { Building } from "@/types";

interface IBuildingCardProps {
  building: Building;
  titleLighted?: boolean;
  className?: string;
  onClick?: () => void;
}

export default function LiteBuildingCard({
  className,
  building,
  titleLighted,
  onClick,
}: Readonly<IBuildingCardProps>) {
  return (
    <Card className={cx("my-3", className)} onClick={onClick}>
      <p
        className={cx(
          "text-lg font-semibold text-neutral-800",
          "mb-1.5",
          titleLighted && "text-primary"
        )}
      >
        {building.property_name}
      </p>
      <BuildingCardItem
        attributeName="棟名"
        icon={<BuildingTwoIcon className="mb-[2px] mr-[3px] inline" />}
        content={building.name}
      />
      <BuildingCardItem
        attributeName="仮称名称"
        icon={<HouseIcon className="mb-[2px] mr-[3px] inline" />}
        content={building?.property_alias}
      />
      <BuildingCardItem
        attributeName="住所"
        icon={<PlacePinIcon className="mb-[2px] mr-[3px] inline" />}
        content={building.address?.full_address}
      />
      <BuildingCardItem
        attributeName="戸数"
        icon={<HeadCountIcon className="mb-[2px] mr-[3px] inline" />}
        content={building?.actual_households}
      />
    </Card>
  );
}
interface IBuildingCardItemProps {
  attributeName: string;
  icon: ReactElement;
  content?: string | number;
}

const BuildingCardItem: FC<IBuildingCardItemProps> = ({
  attributeName,
  content,
  icon,
}) => {
  return (
    <p className={cx("flex items-start", "text-sm text-neutral-600")}>
      <span className="shrink-0">
        {icon}
        {attributeName}：
      </span>
      <span className="hyphens-auto">{content || "-"}</span>
    </p>
  );
};
