import { cx } from "@emotion/css";
import {
  type FC,
  type HTMLAttributes,
  type PropsWithChildren,
  type ReactNode,
  Fragment,
  useState,
} from "react";
import type { UseFormRegisterReturn, UseFormSetValue } from "react-hook-form";
import { Popup as NutPopup } from "@nutui/nutui-react";
import ReactDOM from "react-dom";

type Option =
  | {
      value: string | number;
      label: NonNullable<ReactNode>;
    }
  | string;
interface SelectInputProps extends HTMLAttributes<HTMLInputElement> {
  register: UseFormRegisterReturn<string>;
  setValue: UseFormSetValue<any>;
  styleType?: "shadow" | "outline";
  options?: Option[];
}
export const SelectInput: FC<PropsWithChildren<SelectInputProps>> = ({
  className,
  register,
  styleType = "outline",
  placeholder,
  setValue,
  options,
  ...props
}) => {
  const [isPopup, setIsPopup] = useState(false);
  const handleItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const optionEle = e.target as HTMLParagraphElement;
    if (optionEle.dataset.optionValue) {
      setValue(register.name, optionEle.dataset.optionValue);
    }
  };
  return (
    <div className="flex flex-col">
      <input
        readOnly
        className={cx([
          "form-select text-[15px]  placeholder:text-text-gray rounded-lg p-4 outline-0",
          "focus:border-transparent focus:ring-primary",
          className,
          {
            "bg-[#F2F4F5]  outline-gray-500 border-none":
              styleType === "shadow",
            "border-[#E3E5E5] border-solid": styleType === "outline",
          },
          props.disabled && "bg-none",
        ])}
        {...props}
        {...register}
        placeholder={placeholder}
        onClick={() => setIsPopup(true)}
      />
      {ReactDOM.createPortal(
        <NutPopup
          position="bottom"
          visible={isPopup}
          onClick={() => setIsPopup(false)}
          onOverlayClick={() => setIsPopup(false)}
          style={{ maxHeight: "80vh" }}
          overlayStyle={{ backgroundColor: "rgba(0,0,0,0.4)" }}
          round
        >
          <p className="text-center text-base p-5 pb-0">{placeholder}</p>
          <div
            className="divide-y divide-x-0 divide-solid divide-[#E9EDEE] p-4"
            onClick={handleItemClick}
          >
            {options?.map((optionValue, index) => (
              <Fragment key={index}>
                {typeof optionValue === "string" ? (
                  <p
                    data-option-value={optionValue}
                    className="text-text-gray text-base leading-12 text-center"
                  >
                    {optionValue}
                  </p>
                ) : (
                  <p
                    data-option-value={optionValue.value}
                    className="text-text-gray text-base leading-12 text-center"
                  >
                    {optionValue.label}
                  </p>
                )}
              </Fragment>
            ))}
          </div>
        </NutPopup>,
        document.body,
      )}
    </div>
  );
};
