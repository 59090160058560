import { useMutation } from "@tanstack/react-query";

import { XIEZHEN_DEFAULT_URI } from "@/app.constant";
import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import type { AttachDeviceData } from "@/shared/requests/property";
import {
  attachDeviceToProperty,
  getDevicesOfProperty,
} from "@/shared/requests/property";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError } from "./errors.type";

export function useCreateDearisPositionMulti() {
  const mutateFn = async (buildings: number[] = []) => {
    // 获取devices信息
    const property_id = getStorage("propertyId");
    const gate_number = getStorage("gateNumber");

    if (!property_id || !gate_number) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    // 检测gate_number是否重复
    const devices = await getDevicesOfProperty({
      property_id,
      device_type: "dearisGate",
    });
    const isGateNumberExist = devices.data?.findIndex(
      (item) => item?.position?.gate_number == gate_number
    );
    if (isGateNumberExist !== -1) {
      throw new Error("ゲート番号が重複しています。");
    }
    const sn = getStorage("serialNumber");
    const spot = getStorage("spotName");
    const image_url = getStorage("uri") || XIEZHEN_DEFAULT_URI;
    if (!property_id || !sn || !spot || !image_url) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    const data: AttachDeviceData = {
      property_id,
      sn,
      device_type: "dearisGate",
      positions: [
        ...(buildings?.map((item) => ({
          build_id: item,
          gate_number,
          image_url,
          spot,
        })) ?? []),
      ],
      setup_by: "manual",
    };
    return attachDeviceToProperty(data);
  };
  return useMutation(mutateFn);
}
