import { cx } from "@emotion/css";
import React, { useLayoutEffect, useState } from "react";
import type { FieldErrors } from "react-hook-form";
import { useForm } from "react-hook-form";

import { Button, Popup, PopupAction, PopupMain } from "@/components";
import { Input } from "@/components/form";
import { useFormErrorsToast } from "@/hooks";
import { useAmazonUnlockTest, useUpdateCloudUnlock } from "@/queries";
import { errorToast } from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

interface ElevatorUnlockForm {
  roomNumber: string;
}
export const ElevatorUnlockModal = React.memo(function ({
  visible,
  toggle,
}: {
  visible: boolean;
  toggle: () => void;
}) {
  const deviceId = getStorage("tobeUnlockId");
  const [isInputRoom, setIsInputRoom] = useState(true);
  const { register, setValue, handleSubmit } = useForm<ElevatorUnlockForm>();
  const { toastFormValueError } = useFormErrorsToast();
  const { mutateAsync: unlockDevice, isLoading: isUnlocking } =
    useAmazonUnlockTest();
  const { mutateAsync: updateUnlockTest, isLoading: isUpdating } =
    useUpdateCloudUnlock("dearisElevator");

  const handleSendSignal = async (values: ElevatorUnlockForm) => {
    setStorage("roomNumber", values.roomNumber);
    await unlockDevice(deviceId)
      .then(() => {
        setIsInputRoom(false);
      })
      .catch((error: any) => {
        if (error?.status === 400 || error?.status === 410) {
          errorToast("エラーが返されました、解除できません。");
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };

  const handleConfirmUnlock = () => {
    setStorage("cloudUnlockTest", true);
    updateUnlockTest(deviceId)
      .then(() => {
        toggle();
      })
      .catch((error) => {
        error.status === 400 && errorToast("ロック解除に失敗しました。");
      });
  };

  const handleUnlockFailedClick = () => {
    setStorage("cloudUnlockTest", false);
    updateUnlockTest(deviceId)
      .then(() => {
        toggle();
      })
      .catch((error) => {
        error.status === 400 && errorToast("ロック解除に失敗しました。");
      });
  };

  // TODO 优化
  useLayoutEffect(() => {
    !visible && setIsInputRoom(true);
  }, [visible]);

  if (isInputRoom) {
    return (
      <Popup
        visible={visible}
        closeable
        onCloseIconClick={() => {
          visible && toggle();
        }}
      >
        <PopupMain>
          <p className="mb-2 text-base">
            部屋番号を入力してください&nbsp;
            <span className="text-error">*</span>
          </p>
          <Input
            register={register("roomNumber", {
              required: "部屋番号を入力してください",
              onChange: (e) => {
                const cleaned = e.target.value
                  .replace(/\D/g, "")
                  .substring(0, 4);
                setValue("roomNumber", cleaned);
                setStorage("roomNumber", cleaned);
              },
            })}
          />
        </PopupMain>
        <PopupAction>
          <Button
            onClick={handleSubmit(handleSendSignal, (error: FieldErrors) =>
              toastFormValueError(error)
            )}
            loading={isUnlocking}
          >
            解錠を送信
          </Button>
        </PopupAction>
      </Popup>
    );
  }

  return (
    <Popup
      visible={visible}
      closeable
      onCloseIconClick={() => {
        visible && toggle();
      }}
    >
      <PopupMain>
        <p className="text-base">正しくエレベーターは動作しましたか？</p>
      </PopupMain>
      <PopupAction className={cx(isUpdating && "opacity-20")}>
        <Button onClick={handleConfirmUnlock}>動作しました</Button>
        <button
          className="mt-2 text-center text-sm text-primary"
          onClick={handleUnlockFailedClick}
        >
          動作しませんでした
        </button>
      </PopupAction>
    </Popup>
  );
});
